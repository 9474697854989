define("discourse/plugins/discourse-ai/initializers/admin-plugin-configuration-nav", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-ai-admin-plugin-configuration-nav",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if (!currentUser || !currentUser.admin) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.1.0", api => {
        api.addAdminPluginConfigurationNav("discourse-ai", [{
          label: "discourse_ai.usage.short_title",
          route: "adminPlugins.show.discourse-ai-usage",
          description: "discourse_ai.usage.subheader_description"
        }, {
          label: "discourse_ai.llms.short_title",
          route: "adminPlugins.show.discourse-ai-llms",
          description: "discourse_ai.llms.preconfigured.description"
        }, {
          label: "discourse_ai.ai_persona.short_title",
          route: "adminPlugins.show.discourse-ai-personas",
          description: "discourse_ai.ai_persona.persona_description"
        }, {
          label: "discourse_ai.embeddings.short_title",
          route: "adminPlugins.show.discourse-ai-embeddings",
          description: "discourse_ai.embeddings.description"
        }, {
          label: "discourse_ai.tools.short_title",
          route: "adminPlugins.show.discourse-ai-tools",
          description: "discourse_ai.tools.subheader_description"
        }, {
          label: "discourse_ai.spam.short_title",
          route: "adminPlugins.show.discourse-ai-spam",
          description: "discourse_ai.spam.spam_description"
        }
        // TODO(@keegan / @roman): Uncomment this when structured output is merged
        // {
        //   label: "discourse_ai.features.short_title",
        //   route: "adminPlugins.show.discourse-ai-features",
        //   description: "discourse_ai.features.description",
        // },
        ]);
      });
    }
  };
});