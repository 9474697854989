define("discourse/plugins/discourse-ai/discourse/admin/models/ai-feature", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiFeature extends _rest.default {
    createProperties() {
      return this.getProperties("id", "name", "ref", "description", "enable_setting", "persona", "persona_setting");
    }
  }
  _exports.default = AiFeature;
});